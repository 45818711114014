<!-- Author:孔德昊 -->

<!-- importModule是父组件，select、editFiled、startimport作为子组件放在父组件内 -->
<!-- “开始导入”页面 -->
<template>
  <div class="importmain">
    <div class="imain">
      <div class="header">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-selectgrey"></use>
        </svg>
        <span class="normal">{{
          $t("label.import.index.selectobjectandfiles")
        }}</span
        ><!-- 选择对象与文件-->
        <span>··············································</span>
        <svg class="icon" aria-hidden="true">
          <use href="#icon-editgrey"></use>
        </svg>
        <span class="normal">{{ $t("label.import.index.editfield") }}</span
        ><!--  编辑字段映射 -->
        <span>··············································</span>
        <svg class="icon" aria-hidden="true">
          <use href="#icon-importing"></use>
        </svg>
        <span class="progress">{{ $t("label.import.index.startimport") }}</span
        ><!-- 开始导入 -->
      </div>
      <el-dialog
        :title="$t('label.import.page3.importsucceed')"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="handleClose"
      >
        <div class="box">
          <svg class="icon complete" aria-hidden="true">
            <use href="#icon-done"></use>
          </svg>
          <p style="font-size: 14px; color: #080707; margin-top: 20px">
            {{ $t("label.import.page3.importsucceedtip") }}
            <!--成功开始导入，导入结束后会发送邮件到您绑定的邮箱-->
          </p>
        </div>

        <span class="dialog-footer" style="float: right">
          <el-button @click="backIndex()"
            >{{ $t("label.import.page3.gobackhome")
            }}<!--回到主页--></el-button
          >
          <el-button type="primary" @click="continueImport"
            >{{ $t("label.import.page3.continueimport")
            }}<!--继续导入--></el-button
          >
        </span>
      </el-dialog>
      <div class="nav">
        <p style="font-size: 14px; color: #080707">
          <span
            >{{ $t("label.import.index.obj") }}：{{
              this.$store.state.labelName
            }}</span
          >
          <!-- 对象-->
          <span style="margin-left: 40px"
            >{{ $t("label.import.index.operation") }}：{{
              this.$store.state.swicthtablebal
            }}</span
          ><!-- 操作：-->
          <span style="margin-left: 40px"
            >{{ $t("label.import.page2.filedname") }}：{{
              this.$store.state.filename
            }}</span
          ><!-- 文件名：-->
        </p>
      </div>
      <div style="width: 100%; height: 400px; text-align: center">
        <el-progress
          type="circle"
          :percentage="percentage"
          :width="400"
          :height="100"
          color="#06844B"
          :stroke-width="strokewidth"
        ></el-progress>
      </div>
      <div class="footer">
        <p>
          <span
            >{{ $t("label.import.page2.suited") }}：{{
              this.$store.state.selectlength
            }}</span
          >
          <!-- 已映射：-->
          <span style="margin-left: 40px"
            >{{ $t("label.import.page2.notsuited") }}：{{
              this.$store.state.csvfiledlength - this.$store.state.countnum
            }}
            <!-- 未映射--></span
          >
        </p>
        <p style="font-size: 12px; color: #666666">
          {{ $t("label.import.page2.notsuitedfiledwillbenotimport") }}
          <!--注：系统不会导入您未映射的字段-->
        </p>
      </div>
      <div class="Buttondiv">
        <el-button class="lastButton" @click="lastButton()"
          >{{ $t("label.import.page1.laststep")
          }}<!--上一步--></el-button
        >
        <el-button
          class="nextButton"
          @click="handleStartInput()"
          :loading="buttonloading"
          >{{ $t("label.import.index.startimport")
          }}<!--开始导入--></el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { uploadFile } from './api'
export default {
  data() {
    return {
      buttonloading: false,
      dialogVisible: false,
      strokewidth: 12,
    };
  },
  mounted() {
    this.buttonloading = false;
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === "/importModule/step2") {
        vm.$route.meta.keepalive = true;
      } else {
        vm.$route.meta.keepalive = false;
      }
    });
  },
  methods: {
    lastButton() {
      this.$router.push(`/importModule/step2`);
    },
    handleStartInput() {
      this.buttonloading = true;
      const file = this.$store.state.file;
      this.commit(file);
    },

    // 文件流方式上传，接口请求
    
    commit(file) {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", this.$store.state.filename);
      formData.append("objid", this.$store.state.id);
      formData.append("operate", this.$store.state.defaultselect);
      formData.append("fieldList", [
        JSON.stringify(this.$store.state.commitdata),
      ]);
      formData.append("isexctrigger", this.$store.state.checked1); //触发器
      formData.append("isexcvalidate", this.$store.state.checked2); //验证规则
      formData.append("isdupecatcher", this.$store.state.checked3); //查重规则
      formData.append("isSharingRule", this.$store.state.checked4); //共享规则
      formData.append("validateField", this.$store.state.customer); //匹配客户方式，非必选
      formData.append("validateField2", this.$store.state.contact); //匹配联系人方式，非必选
      formData.append("isGenerateJwd", "false"); //经纬度,老系统用到的参数，导入功能用不到，写死false即可

      uploadFile(formData).then((res) => {
        if (res.result) {
          this.dialogVisible = true;
          this.buttonloading = false;
        } else {
          this.$message({
            message: this.$i18n.t("label.file.upload.fail"),
            type: "warning",
          });
        }
      });
    },

    handleClose() {
      this.dialogVisible = false;
      this.$router.push(`/importModule`);
    },
    //回到主页
    backIndex() {
      this.dialogVisible = false;
      this.$router.push(`/importModule`);
    },
    //继续导入
    continueImport() {
      this.dialogVisible = false;
      this.$router.push(`/importModule/step1`);
    },
  },

  // 计算百分比
  computed: {
    percentage() {
      let percentage =
        this.$store.state.selectlength / this.$store.state.csvfiledlength;
      if (Math.ceil(percentage * 100) > 100) {
        return 100;
      } else {
        return Math.ceil(percentage * 100);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-cascader__dropdown {
  display: none !important;
}
.box {
  width: 100%;
  height: 620px;
  text-align: center;
  vertical-align: center;
}
.complete {
  vertical-align: center;
  width: 300px;
  height: 300px;
  margin-top: 76px;
  text-align: center;
}
.Buttondiv {
  margin-top: 30px;
  float: right;
  outline: none;
}

.lastButton {
  color: #666;
  border: 1px solid #e2e2e2;
}
.nextButton {
  background-color: #006dcc;
  color: #fff;
}
.lastButton:hover {
  background-color: #fff;
  color: #666;
  border: 1px solid #e2e2e2;
}
.importmain {
  height: 100%;
  overflow: auto;
  background-color: #ffffff;
  margin: 10px;
}
.imain {
  width: 100%;
  height: 100%;
  padding: 0 140px 0 140px;
  .header {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 36px;
      height: 36px;
    }
  }
  .nav {
    width: 100%;
    height: 80px;
  }
  .footer {
    width: 100%;
    height: 80px;
    text-align: center;
    margin-top: 20px;
  }
  .footer > p:nth-child(1) {
    font-family: SourceHanSansCN-Medium;
    font-size: 16px;
    color: #080707;
    letter-spacing: 0;
  }
}
.normal {
  font-size: 16px;
  color: #191717;
  margin: 0 5px 0 5px;
}
.progress {
  font-family: SourceHanSansCN-Regular;
  font-size: 16px;
  color: #006dcc;
  letter-spacing: 0;
  margin: 0 5px 0 5px;
}

.header > svg {
  width: 36px;
  height: 36px;
  margin-left: 6px;
}
.des {
  font-family: SourceHanSansCN-Normal;
  font-size: 14px;
  color: #080707;
  letter-spacing: 0;
}
</style>
